import React, { useState } from "react";
import { Box, ThemeProvider, Typography } from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import ErrorLogModal from "./error-log-model"; // Assuming this is the path
import { ApiErrorLogData } from "../../config";
import "./index.css";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Correct icon
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; // Error icon

interface ErrorLogsListProps {
  errorLogsValid: boolean;
  errorLogsList?: ApiErrorLogData[];
}

const getMuiTheme = () => createTheme({
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        contentWrapper: {
          justifyContent: "left",
        },
      },
    },
  },
});

export default function ErrorLogsList({ errorLogsValid, errorLogsList }: ErrorLogsListProps) {
  const [selectedFunction, setSelectedFunction] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleFunctionClick = (functionName: string) => {
    setSelectedFunction(functionName);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  const data = (errorLogsValid && errorLogsList) || [];
  const columns = [
    {
      label: "LAMBDA FUNCTION",
      name: "functionName",
      options: {
        customBodyRender: (value: string) => (
          <span className="lambda-link" onClick={() => handleFunctionClick(value)}>
            {value}
          </span>
        )
      }
    },
    {
      label: "STATUS",
      name: "state",
      options: {
        customBodyRender: (value: string) => {
          switch(value) {
            case "OK":
              return (
                <Typography component="div" style={{ color: 'green' }}>
                  <CheckCircleOutlineIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> OK
                </Typography>
              );
            case "Error":
              return (
                <Typography component="div" style={{ color: 'red' }}>
                  <HighlightOffIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> Error
                </Typography>
              );
            default:
              return value; // Default text if status is neither "OK" nor "Error"
          }
        }
      }
    }
  ];

  const options = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: true,
    print: false,
    responsive: "vertical",
    search: true,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: errorLogsValid ? "No Error Logs available." : "Loading ...",
      },
    },
  };

  return (
    <Box className="errorLogs-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable title={""} data={data} columns={columns} options={options as any} />
      </ThemeProvider>
      {selectedFunction && (
        <ErrorLogModal
          selectedFunction={selectedFunction}
          modalOpen={modalOpen}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
}