import { Box } from "@mui/material";
import bssiLogo from "../../assets/images/bssi_logo_light.png";
import Page from "../../components/Page";
import "./index.css";

const HEADER_NAME = "";

const About = () => (
  <Page name={HEADER_NAME}>
    <Box className="about-container">
      <img src={bssiLogo} className="about-image" alt="about" />
    </Box>
  </Page>
);

export default About;
