import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Paper, Typography, IconButton, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import config from '../../config';
import FrontEndContext from '../../context/FrontEndContext';
import axios from 'axios';

const {ERRORLOGS_LAMBDA} = config?.api;

interface LogEntry {
  timestamp: string;
  message: string;
  logStreamName: string;
}

interface ErrorLogModalProps {
  selectedFunction: string;
  modalOpen: boolean;
  onClose: () => void;
}

const formatTimestamp = (timestamp: number) => {
  // Convert unix timestamp to milliseconds
  const milliseconds = timestamp * 1000;
  // Format the date according to the given format (dd/MM/yyyy, HH:mm:ss)
  return format(new Date(milliseconds), 'dd/MM/yyyy, HH:mm:ss');
}

const ErrorLogModal = ({ selectedFunction, modalOpen, onClose }: ErrorLogModalProps) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { headersWithAuth } =
  React.useContext(FrontEndContext);


  const awsRegion = 'ca-central-1'; 
  const logGroupName = encodeURIComponent(`/aws/lambda/${selectedFunction}`);


  const fetchLogs = useCallback(async (startDate?: string, endDate?: string, token?: string) => {
    if (!selectedFunction) return;

    setLoading(true);
    console.log('fetchLogs', selectedFunction, startDate, endDate, token);
    console.log("headersWithAuth", headersWithAuth);

    try {
        let axiosURL = `${ERRORLOGS_LAMBDA}?lambdaName=${selectedFunction}`;

        if (token) {
          axiosURL += `&nextToken=${token}`;
      } else {
          if (startDate) axiosURL += `&startDate=${startDate}`;
          if (endDate) axiosURL += `&endDate=${endDate}`;
      }
        const response = await axios.get(axiosURL, headersWithAuth)
        const data = await response.data;

        if (response.status === 200 && response.data.logs.length > 0) {
            setLogs(prevLogs => [...prevLogs, ...data.logs]);
            setNextToken(data.nextToken);
        }else if (response.data.logs.length === 0) {
          setError('No error logs present in the last 90 days for this function.');
        }  
        else {
            setError('Failed to fetch logs');
        }
    } catch (err) {
        setError('Network error');
        console.error(err);
    } finally {
        setLoading(false);
    }
}, [selectedFunction, headersWithAuth]); 


  useEffect(() => {
    if (modalOpen) {
      fetchLogs();
    }
  }, [modalOpen, fetchLogs]);

  useEffect(() => {
    if (!modalOpen) {
      setLogs([]);
      setNextToken(null);
      setError(null);
    }
  }, [modalOpen]);

  // Handle infinite scrolling
  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if ((target.scrollHeight - target.scrollTop - target.clientHeight < 10) && nextToken && !loading) {
        fetchLogs(undefined, undefined, nextToken);
    }
  }, [loading, nextToken, fetchLogs]);

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <Paper sx={{
        position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: '80%', height: '80%', overflow: 'auto', p: 4,
      }} onScroll={handleScroll}>
        <Typography variant="h6">{selectedFunction} Logs</Typography>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Log Stream Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell>{formatTimestamp(parseInt(log.timestamp)/1000)}</TableCell>
                  <TableCell>{log.message}</TableCell>
                  <TableCell><a href={`https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/${logGroupName}/log-events/${encodeURIComponent(log.logStreamName)}`}
                      target="_blank" rel="noopener noreferrer">
                      {log.logStreamName}
                    </a></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}><CircularProgress /></Box>}
        {error && <Typography color="error">{error}</Typography>}
      </Paper>
    </Modal>
  );
};

export default ErrorLogModal;