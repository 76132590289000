import { ApiCompanyData } from "./config";

const Utils = {
  getCompaniesOptions: (companies: ApiCompanyData[], selectedId: number | undefined) => {
    const options: any[] = [];

    if (selectedId === undefined) {
      options.push(
        <option key={-1} value={-1}>
          {"<select company>"}
        </option>
      );
    }

    if (companies && companies.length > 0) {
      companies.forEach((company) =>
        options.push(
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        )
      );
    }

    return options;
  },
};

export default Utils;
