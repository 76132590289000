import ExpandableRowContent from "../ExpandableRowContent";
import { useNavigate } from "react-router-dom";

import MUIDataTable, {
  FilterType,
  Responsive,
  SelectableRows,
  MUIDataTableColumn,
} from "mui-datatables";
import { AlertColor, Box, createTheme, ThemeProvider } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
// import { ApiSystemExtraDetails } from "../../config";

import "./index.css";
import config, { ApiSystemData, PresignedUrlResponse } from "../../config";
import ButtonWithDialog from "../DialogButton";

import axios from "axios";
import CustomButton from "../CustomButton";
import React, { SyntheticEvent, useEffect, useState } from "react";
import CustomSnackbar from "../../components/CustomSnackbar";
import FrontEndContext from "../../context/FrontEndContext";

const { SYSTEM_CERTS, DELETE_SYSTEMS, API_FAIL_ERROR } = config?.api;

const { ID_CREATE_NEW } = config.app;

interface SystemsListProps {
  systemsValid: boolean;
  systems?: ApiSystemData[];
  preSigned?: PresignedUrlResponse[];
}

export default function SystemsList(props: SystemsListProps) {
  const { systemsValid, systems, preSigned } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");
  const navigate = useNavigate();
  const data = (systemsValid && systems) || [];
  const { isAuthenticated, isAuthenticating, refreshSystems, headersWithAuth } =
    React.useContext(FrontEndContext);

  const [, setIsLoading] = useState(false);

  function formatDateTime(datetime: string): string {
    if (!datetime) return "-";
    const date = new Date(datetime);
    console.log(date);
    return date.toLocaleString();
  }

  // Add the default last login time to each user in the data array
  const dataWithDefaultLastLogin = data.map((system) => ({
    ...system,
    updateTs: formatDateTime(system.updateTs),
  }));

  const systemIds = data.map((sys) => {
    return { id: sys.id };
  });
  console.log(systemIds);
  // const manageSystemData = (data: any) => {
  //   console.log("entered manageSystemData");
  //   console.log(data);
  // };

  // const response = axios.post<Array<PresignedUrlResponse>>(
  //   SYSTEM_CERTS,
  //   systemIds,
  //   headersWithAuth
  // );

  // console.log(response);

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const options = {
    setCellProps: () => ({
      align: "left",
    }),
  };

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const buttonViewOptions = {
    sort: false,
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <CustomButton
        onClick={() => navigate(`/system/${value}`)}
        variant="contained"
        isLoading={false}
        disabled={false}
        // text="Download"
        icon={EditRoundedIcon}
        iconColor="#1b76d2"
      />
    ),
  };

  const handleDelete = async (id: string) => {
    try {
      console.log("entered handle delete");
      console.log(id);
      setIsLoading(true);
      setOpenSnackbar(true);
      setSnackbarMessage(`System delete in progress`);
      setSnackbarSeverity("success");
      const response = await axios.post(
        `${DELETE_SYSTEMS}`,
        { id: id },
        headersWithAuth
      );
      console.log(response.status);
      if (response.status === 200) {
        console.log("checking if condition");
        console.log("opensnackbar", openSnackbar);
        setSnackbarMessage("System deleted successfully!");
        setSnackbarSeverity("success");
        refreshSystems();
      } else {
        console.log(`Deleted ${API_FAIL_ERROR}${response.status}`);
        setSnackbarMessage("Failed to delete system.");
        setSnackbarSeverity("error");
      }
    } catch (error: any) {
      setSnackbarMessage(
        `Error: ${error.message || "Failed to delete system"}`
      );
      setSnackbarSeverity("error");
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleDownload = (systemId: string) => {
    const fetchPresignedUrl = async () => {
      try {
        setIsLoading(true);
        setOpenSnackbar(true);
        const payload = {
          systems: [
            {
              id: systemId,
            },
          ],
        };
        setSnackbarMessage(
          `Started downloading the certificate for SystemId: ${systemId} ...`
        );
        setSnackbarSeverity("success");
        const response = await axios.post<Array<PresignedUrlResponse>>(
          SYSTEM_CERTS, // Endpoint from config
          payload,
          headersWithAuth // Authentication headers
        );

        if (response.status >= 200 && response.status < 300) {
          const responseData = response.data;
          if (responseData[0].success && responseData[0].url) {
            await downloadFile(responseData[0].url, systemId);

            setSnackbarMessage(
              `System certificate download complete for the id: ${systemId}`
            );
            setSnackbarSeverity("success");
          } else {
            console.error(
              `Failed to fetch presigned URL. Error: ${responseData[0].error}`
            );
          }
        } else {
          console.error(
            `Failed to fetch presigned URL. Status: ${response.status}`
          );
          setSnackbarMessage(
            `Error fetching presigned URL for systemId: ${systemId}`
          );
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error(
          `Error fetching presigned URL: ${API_FAIL_ERROR}${error}`
        );
        setSnackbarMessage(
          `Error fetching presigned URL for systemId: ${systemId}`
        );
        setSnackbarSeverity("error");
      } finally {
        setIsLoading(false);
      }
    };

    const downloadFile = async (presignedUrl: string, systemId: string) => {
      try {
        const response = await axios.get(presignedUrl, {
          headers: headersWithAuth,
          responseType: "blob",
        });
        const filename = `${systemId}_certs.zip`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error(`Error downloading the file: ${API_FAIL_ERROR}${error}`);
        setSnackbarMessage(
          `System certificate not downloaded for the id: ${systemId}!`
        );
        setSnackbarSeverity("error");
      }
    };

    fetchPresignedUrl();
  };

  const allSystems = systems || [];

  const deleteButton = {
    sort: false,
    filter: false,
    viewColumns: false,

    customBodyRender: (SystemId: string) => {
      const systemToDelete = allSystems.find(
        (system) => system.id === SystemId
      );
      if (!systemToDelete) {
        return null;
      }
      return (
        <ButtonWithDialog
          onDelete={() => handleDelete(systemToDelete.id)}
          typeOfOperation="Delete"
        />
      );
    },
    // setCellProps: () => ({
    //   className: "icon-column",
    // }),
  };

  // const downloadButton = {
  //   filter: false,
  //   viewColumns: false,
  //   customBodyRender: (value: string) => (
  //     <CustomButton
  //       onClick={() => handleDownload(value)}
  //       variant="contained"
  //       isLoading={false}
  //       disabled={false}
  //       // text="Download"
  //       icon={DownloadIcon}
  //       iconColor="#1b76d2"
  //     />
  //   ),
  //   // setCellProps: () => ({
  //   //   className: "icon-column",
  //   // }),
  // };

  const downloadButton = {
    sort: false,
    filter: false,
    viewColumns: false,
    customBodyRender: (systemId: string) => {
      const system = allSystems.find((sys) => sys.id === systemId);
      const isProvisioned = system?.status === "provisioned";

      return (
        <CustomButton
          onClick={() => handleDownload(systemId)}
          variant="contained"
          isLoading={false}
          disabled={!isProvisioned}
          icon={DownloadIcon}
          iconColor={isProvisioned ? "#1b76d2" : ""}
        />
      );
    },
  };

  const refreshButton = {
    sort: false,
    filter: false,
    viewColumns: false,
    customBodyRender: (value: string) => (
      <CustomButton
        onClick={() => alert("Under Development")}
        variant="contained"
        isLoading={false}
        disabled={false}
        // text="Download"
        icon={RefreshIcon}
        iconColor="#1b76d2"
      />
    ),
  };

  const newSystemTitle = (
    <CustomButton
      data-cy="create-system-button"
      onClick={() => navigate(`/system/${ID_CREATE_NEW}`)}
      variant="primary"
      isLoading={false}
      disabled={false}
      text="Create System"
      // icon={ControlPointRoundedIcon}
    />
  );

  const COLUMNS = [
    { label: " ", name: "id", options: buttonViewOptions },
    { label: " ", name: "id", options: downloadButton },
    { label: " ", name: "id", options: deleteButton },
    { label: " ", name: "id", options: refreshButton },
    { label: "ID", name: "id", options },
    { label: "Name", name: "name", options },
    { label: "Company", name: "companyName", options },
    { label: "System Update Time", name: "updateTs", options },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: "systems",
    textLabels: {
      body: {
        noMatch: systemsValid ? "No systems available." : "Loading ...",
      },
    },
    expandableRows: true,
    renderExpandableRow: (rowData: any[], rowMeta: { dataIndex: number }) => {
      const colSpan = rowData.length + 1;
      const systemId = rowData[4];
      // console.log("systemId", systemId);

      return (
        <tr data-cy={`expandable-row-${systemId}`}>
          <td colSpan={colSpan}>
          <ExpandableRowContent systemId={systemId} />
          </td>
        </tr>
      );
    },

    // customHeadRender: (
    //   columnMeta: MUIDataTableColumn,
    //   updateDirection: any
    // ) => {
    //   const widthMap: Record<string, string> = {
    //     id: "100px", // Set the width for the "USER NAME" column
    //     name: "100px", // Set the width for the "ENTITY TYPE" column
    //     companyName: "100px", // Set the width for the "OPERATION" column
    //     updateTS: "100px", // Set the width for the "OPERATION VALUES" column
    //   };

    //   return (
    //     <th
    //       key={columnMeta.name}
    //       style={{ width: widthMap[columnMeta.name] || "auto" }}
    //       //style={{ padding: '1px 1px', margin: '1px', width: widthMap[columnMeta.name],fontSize: '14px' }}
    //     >
    //       {columnMeta.label}
    //     </th>
    //   );
    // },
    // setCellProps: () => ({
    //   align: "center",
    // }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "left",
            },
          },
        },
      },
    });
  // manageSystemData(data);
  console.log("data", dataWithDefaultLastLogin);
  return (
    <Box className="systems-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={newSystemTitle}
          data={dataWithDefaultLastLogin}
          columns={COLUMNS}
          options={OPTIONS}
        />
      </ThemeProvider>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={30000}
      />
    </Box>
  );
}
