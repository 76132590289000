import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import bssiLogo from "../../assets/images/bssi_logo_light.png";
import "./index.css";
import { Box } from "@mui/material";

export interface HeaderBarProps {
  name?: string;
  hideAuthentication?: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
  const navigate = useNavigate();
  const { name, hideAuthentication } = props;

  const { isAuthenticating, isAuthenticated, doLogin, doLogout } = useContext(FrontEndContext);
  const authText = isAuthenticating ? "Logging in..." : isAuthenticated ? "Logout" : "Login";
  const authHandler = isAuthenticated ? doLogout : doLogin;

  return (
    <Box className="header-container">
      <Box className="left-items">
        <Box>
          <img className="bssi-logo" onClick={() => navigate("/")} src={bssiLogo} alt="BSSI logo" />
        </Box>
      </Box>
      <Box className="middle-items">
        <Box className="header-text">{name || "Admin Console"}</Box>
      </Box>
      <Box className="right-items">
        {!hideAuthentication && (
          <Box className="header-text auth-text" onClick={() => !isAuthenticating && authHandler()}>
            {authText}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderBar;
