import { Box } from "@mui/material";
import HeaderBar from "../HeaderBar";
import "./index.css";

export interface PageProps {
  name: string;
  isAuthenticating?: boolean;
  hideAuthentication?: boolean;
  children?: React.ReactNode;
}

const Page = (props: PageProps) => {
  const { name, isAuthenticating, hideAuthentication, children } = props;
  return (
    <Box className="page-container">
      <Box className="navbar-container">
        <HeaderBar name={name} hideAuthentication={hideAuthentication || isAuthenticating} />
      </Box>
      <Box className="child-container">{children}</Box>
    </Box>
  );
};

export default Page;
