import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface BackButtonProps {
  children?: React.ReactNode;
  // ... other props ...
}

const BackButton: React.FC<BackButtonProps> = ({ children }) => {
  const navigate = useNavigate(); // Correct usage

  return (
    <CustomButton
      isLoading={false}
      disabled={false}
      variant="secondary"
      type="button"
      icon={ArrowBackIcon}
      text="Go Back"
      onClick={() => navigate(-1)} // Use `navigate` function with a delta of -1 to go back
    />
  );
};

export default BackButton;
