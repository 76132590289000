import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box } from "@mui/material";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import LoginButton from "../../components/LoginButton";
import "./index.css";

const HEADER_NAME = "";

export default function Login() {
  const navigate = useNavigate();
  const { handleAuthentication } = useContext(FrontEndContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      handleAuthentication(user.getSignInUserSession());
      navigate("/");
    } catch (e) {
      setIsLoading(false);
      setErrorMessage('Invalid username or password. Please try again.');
      handleAuthentication(null, e);
    }
  }

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Page name={HEADER_NAME} hideAuthentication={true}>
      <Box className="login">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Username</Form.Label>
            <Form.Control data-cy="username-input" autoFocus type="text" value={email} onChange={handleSetEmail} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control data-cy="password-input" type="password" value={password} onChange={handleSetPassword} />
          </Form.Group>
          <LoginButton size="lg" type="submit" data-cy="login-button" isLoading={isLoading} disable={!validateForm()}>
            Login
          </LoginButton>
        </Form>
        <div data-cy="error-message" className="error-message">
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </Box>
    </Page>
  );
}
