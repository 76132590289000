import React from "react";
import {
  ApiCompanyData,
  ApiUserData,
  ApiSystemData,
  ApiErrorLogData,
  PresignedUrlResponse,
  ApiLambdaErrorLog
} from "../config";

export interface FrontEndContextInterface {
  handleAuthentication: (userSession: any, error?: any) => void;
  headersWithAuth: any;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  doLogin: () => void;
  doLogout: () => void;
  companiesValid: boolean;
  companies: ApiCompanyData[];
  usersValid: boolean;
  users: ApiUserData[];
  systemsValid: boolean;
  systems: ApiSystemData[];
  preSigned: PresignedUrlResponse[];
  errorLogsValid: boolean;
  errorLogs: ApiErrorLogData[];
  errorLambdaLogs: ApiLambdaErrorLog[]; 
  refreshUsers: () => Promise<void>;
  refreshSystems: () => Promise<void>;
  refreshCompanies: () => Promise<void>;

}

const FrontEndContext = React.createContext<FrontEndContextInterface>({
  handleAuthentication: (userSession: any, error?: any) => {},
  headersWithAuth: {},
  isAuthenticating: false,
  isAuthenticated: false,
  doLogin: () => {},
  doLogout: () => {},
  companiesValid: false,
  companies: [],
  usersValid: false,
  users: [],
  systemsValid: false,
  systems: [],
  preSigned: [],
  errorLogsValid: false,
  errorLogs: [],
  errorLambdaLogs: [],
  refreshUsers: async () => {},
  refreshSystems: async () => {},
  refreshCompanies: async () => {},
});

export default FrontEndContext;
