import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import { AlertColor, Box } from "@mui/material";
import { Form } from "react-bootstrap";
import config, { ApiSystemData } from "../../config";
import axios from "axios";
import Utils from "../../utils";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomButton from "../../components/CustomButton";
import BackButton from "../../components/BackButton";

const { ID_CREATE_NEW } = config?.app;
const { CREATE_SYSTEMS, SYSTEMS, UPDATE_SYSTEMS, API_FAIL_ERROR } = config?.api;

const HEADER_NAME = "";

const System = () => {
  const { systemId } = useParams();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isAuthenticating,
    refreshSystems,
    systems,
    companies,
    headersWithAuth,
  } = React.useContext(FrontEndContext);

  const isCreatingNew = systemId === ID_CREATE_NEW;

  const [initialSystemData, setInitialSystemData] = useState<
    ApiSystemData | undefined
  >(undefined);
  const [updatedSystemData, setUpdatedSystemData] = useState<
    ApiSystemData | undefined
  >(undefined);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");

  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const isSystemChanged = (): boolean => {
    const noChange =
      !!initialSystemData &&
      !!updatedSystemData &&
      Object.is(initialSystemData, updatedSystemData);
    return !noChange;
  };

 const validateSystemId = (id?: string) => id && id.length === 32;
 //const validateSystemId = (id?: string) => !id || id.length === 32;
  
 const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (systems) {
      const systemFromId = systems.find(
        (system) => system.id.toString() === systemId
      );
      setUpdatedSystemData(systemFromId);
      setInitialSystemData(systemFromId);
    }
  }, [systemId, systems]);

  const handleUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    const newSystemData: ApiSystemData = {
      ...(updatedSystemData as ApiSystemData),
    };
    if (prop === "companyId") {
      newSystemData.companyId = parseInt(e.target.value);
    } else {
      (newSystemData as any)[prop] = e.target.value;
    }
    setUpdatedSystemData(newSystemData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sendRequest = async () => {
      const systems = [
        {
          id: updatedSystemData?.id,
          companyId: updatedSystemData?.companyId,
          name: systemNameRef.current?.value,
        },
      ];

      let userAction = isCreatingNew ? "Created" : "Updated";

      try {
        setIsLoading(true);
        let createSystemResponse;
        setOpenSnackbar(true);
        if (!isCreatingNew) {
          console.log("entered update condition");
          setSnackbarMessage("System Update in Progress");
          setSnackbarSeverity("success");
          createSystemResponse = await axios.post(
            `${UPDATE_SYSTEMS}`,
            {
              id: updatedSystemData?.id,
              companyId: updatedSystemData?.companyId,
            },
            headersWithAuth
          );
        } else {
          setSnackbarMessage("System Provisioning in process");
          setSnackbarSeverity("success");
          createSystemResponse = await axios.post(
            `${CREATE_SYSTEMS}`,
            { systems },
            headersWithAuth
          );
          console.log("entered create condition");
        }
        const errorCode = createSystemResponse?.data[0]?.errorCode;
        if (errorCode){
          setSnackbarMessage(
            `${createSystemResponse?.data[0]?.message}`
          );
          setSnackbarSeverity("error");
        }

        else if (createSystemResponse?.status >= 400) {
          console.log(
            `${userAction} System ${API_FAIL_ERROR}${createSystemResponse?.status}`
          );
          setSnackbarMessage(
            `${userAction} ${API_FAIL_ERROR}${createSystemResponse?.status}`
          );
          setSnackbarSeverity("error");
        } else if (isCreatingNew) {
          const currentSystemId = systems[0].id;
          const timeToCallApi = 2000;
          const numMinuteForTimeout = 5;
          const maxNumCall = (numMinuteForTimeout * 60 * 1000) / timeToCallApi;

          for (let i = 0; i <= maxNumCall; i++) {
            try {
              const systemsResponse = await axios.post<ApiSystemData[]>(
                SYSTEMS,
                {},
                headersWithAuth
              );
              const isProvisioned = systemsResponse.data.some((sys) => {
                if (
                  currentSystemId === sys.id &&
                  sys.status === "provisioned"
                ) {
                  setSnackbarMessage(`System ${userAction} successfully!`);
                  setSnackbarSeverity("success");
                  return true;
                }
                return false;
              });

              if (isProvisioned) break;

              await new Promise((resolve) =>
                setTimeout(resolve, timeToCallApi)
              );
            } catch (pollingError) {
              console.error("Error polling systems:", pollingError);
            }
          }
        } else {
          setInitialSystemData(updatedSystemData);
          setSnackbarMessage(`System ${userAction} successfully!`);
          setSnackbarSeverity("success");
        }
      } catch (e) {
        console.log(`${userAction} System ${API_FAIL_ERROR}${e}`);
        setSnackbarMessage(`${userAction} System ${API_FAIL_ERROR}${e}`);
        setSnackbarSeverity("error");
      } finally {
        setIsLoading(false);
      }
    };
    await sendRequest();
    refreshSystems();
    setOpenSnackbar(true);
  };

  const pageTitle = isCreatingNew ? `New System` : `System Id: ${systemId}`;

  const name = updatedSystemData?.name || "";
  const id = updatedSystemData?.id || "";
  const companiesOptions = Utils.getCompaniesOptions(
    companies,
    updatedSystemData?.companyId
  );
  const systemNameRef = React.useRef<HTMLInputElement>(null);

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <Box className="home-container py-3">
        <Box sx={{ padding: 2 }}>
          <h1>{pageTitle}</h1>
          <hr />
          <Form onSubmit={handleSubmit}>
            {isCreatingNew && (
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  type="text"
                  value={id}
                  maxLength={32}
                  onChange={(e: any) => handleUpdate(e, "id")}
                />
               <small
                  style={{
                  fontStyle: "italic",
                  color: "grey",
                  fontSize: "0.8em", // Adjust the size as needed
                }}
              >
                System Id should be of 32 characters it cannot be blank. (current length: {id.length})
              </small>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={!isCreatingNew || name.length === 0}
                ref={systemNameRef}
                type="text"
                value={name || "NEW SYSTEM"}
                onChange={(e: any) => handleUpdate(e, "name")}
                readOnly={isCreatingNew}
                className="readonly"
                onFocus={() => setIsNameFieldFocused(true)}
                onBlur={() => setIsNameFieldFocused(false)}
              />
              {!isCreatingNew && isNameFieldFocused && (
                <div className="text-danger">This field is not editable</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>Company</Form.Label>
              <Form.Select
                disabled={!isCreatingNew || companies.length === 0}
                aria-label="Company selection list"
                value={(updatedSystemData?.companyId !== undefined
                  ? updatedSystemData?.companyId
                  : companiesOptions[0]?.props?.value || "unknown"
                ).toString()}
                onChange={(e: any) => handleUpdate(e, "companyId")}
              >
                {companiesOptions}
              </Form.Select>
            </Form.Group>

            <Box display="flex" gap={2}>
              <BackButton>Back</BackButton>
              <CustomButton
                type="submit"
                disabled={!isSystemChanged() || isLoading || !validateSystemId(updatedSystemData?.id)||!updatedSystemData?.companyId}
                isLoading={isLoading}
                text="Submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={isCreatingNew ? 30000 : 6000}
      />
    </Page>
  );
};

export default System;
