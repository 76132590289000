import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import axios from 'axios';
import "./index.css";
import config from '../../config';
import FrontEndContext from "../../context/FrontEndContext";

const { OPERATIONHISTORIES } = config.api;

interface Log {
  userName: string;
  entityType: string;
  operation: string;
  operationValues: string;
  timestamp: string;
}

interface ApiResponse {
  logs: Log[];
  numberOfPages: number;
  pagesLeft: number;
}

const OperationHistoryList: React.FC = () => {
  const [operationHistories, setOperationHistories] = useState<Log[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { isAuthenticated, headersWithAuth } = React.useContext(FrontEndContext);



  const fetchOperationHistories = useCallback(async (page: number): Promise<void> => {
    if (!headersWithAuth) return; // Guard to prevent execution if headers are not ready

    try {
      const response = await axios.post<ApiResponse>(OPERATIONHISTORIES, { pageNumber: page }, headersWithAuth);
      if (response.status === 200) {
        setOperationHistories(response.data.logs);
        setTotalPages(response.data.numberOfPages);
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching operation histories:', error);
    }
  }, [headersWithAuth]);


  useEffect(() => {
    console.log('OperationHistoryList useEffect', headersWithAuth, isAuthenticated);
    if (headersWithAuth && isAuthenticated) {
      fetchOperationHistories(currentPage);
    }
  }, [headersWithAuth, isAuthenticated, currentPage, fetchOperationHistories]);
  const changePage = (page: number): void => {
    setCurrentPage(page);
  };

  const columns = [
    { name: "userName", label: "USER NAME" },
    { name: "entityType", label: "ENTITY TYPE" },
    { name: "operation", label: "OPERATION" },
    { name: "operationValues", label: "OPERATION VALUES" },
    { name: "timestamp", label: "TIMESTAMP", options: { customBodyRender: (value: string) => new Date(value).toLocaleString() } },
  ];

  const options: MUIDataTableOptions = {
    filter: false,
    responsive: 'vertical',
    serverSide: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    count: totalPages * 10,
    page: currentPage - 1,
    onTableChange: (action: string, tableState: any) => {
      if (action === 'changePage') {
        changePage(tableState.page + 1);
      }
    },
  };

  return (
    <Box className="operationHistories-table">
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          title="Operation History"
          data={operationHistories}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </Box>
  );
};

export default OperationHistoryList;
