import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from "aws-amplify";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import FrontEndContext, {
  FrontEndContextInterface,
} from "./context/FrontEndContext";
import Home from "./pages/Home";
import User from "./pages/User";
import Company from "./pages/Company";
import System from "./pages/System";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Login from "./pages/Login";

import config, {
  ApiUserData,
  ApiSystemData,
  ApiCompanyData,
  ApiErrorLogData,
  PresignedUrlResponse,
  ApiErrorLogDataResponse,
  ApiLambdaErrorLog,
} from "./config";

const { USERS, COMPANIES, SYSTEMS, SYSTEM_CERTS, API_FAIL_ERROR, ERRORLOGS } =
  config?.api;

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bearerToken, setBearerToken] = useState("");
  const [usersValid, setUsersValid] = useState(false);
  const [users, setUsers] = useState<ApiUserData[]>([]);
  const [companiesValid, setCompaniesValid] = useState(false);
  const [companies, setCompanies] = useState<ApiCompanyData[]>([]);
  const [systemsValid, setSystemsValid] = useState(false);
  const [systems, setSystems] = useState<ApiSystemData[]>([]);
  const [preSigned, setPreSigned] = useState<PresignedUrlResponse[]>([]);
  const [errorLogsValid, setErrorLogsValid] = useState(false);
  const [errorLogs, setErrorLogs] = useState<ApiErrorLogData[]>([]);
  const [errorLambdaLogs] = useState<ApiLambdaErrorLog[]>([]);



  const navigate = useNavigate();

  const headersWithAuth: any = useMemo(() => {
    if (!bearerToken) {
      return undefined;
    }
    return { headers: { Authorization: `Bearer ${bearerToken}` } };
  }, [bearerToken]);

  const resetApp = () => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setBearerToken("");
  };

  const handleAuthentication = useCallback((userSession: any, error?: any) => {
    if (userSession) {
      setIsAuthenticated(true);
      setBearerToken(userSession.getIdToken().getJwtToken());
    } else {
      // When not logged in, reset the app
      resetApp();

      // No user is signed in. This is not an error but is returned as an exception.
      if (error !== "No current user") {
        setBearerToken("");
        const errorMsg = `Authentication error: ${(error as any)?.message || "unknown error"
          }`;
        console.log(errorMsg);
        alert(errorMsg);
      }
    }

    setIsAuthenticating(false);
  }, []);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const currentSession = await Auth.currentSession();
        handleAuthentication(currentSession);
      } catch (e) {
        handleAuthentication(null, e);
      }
    };

    authenticateUser();
  }, [handleAuthentication]);

  // retrieve the list of users
  const getUsersData = useCallback(async () => {
    try {
      const usersResponse = await axios.post<ApiUserData[]>(
        USERS,
        {},
        headersWithAuth
      );
      if (usersResponse?.status >= 400) {
        console.log(`[Users] ${API_FAIL_ERROR}${usersResponse?.status}`);
      }

      const usersData: ApiUserData[] = usersResponse?.data;
      if (usersData && usersData.length > 0) {
        setUsers(usersData);
      } else {
        const errorMsg = "[Users] No users returned.";
        console.log(errorMsg);
      }
    } catch (error) {
      console.log(`[Users] ${API_FAIL_ERROR}${error}`);
    }
  }, [headersWithAuth]);

  const getCompaniesData = useCallback(async () => {
    try {
      const companiesResponse = await axios.post<ApiCompanyData[]>(
        COMPANIES,
        {},
        headersWithAuth
      );
      if (companiesResponse?.status >= 400) {
        console.log(
          `[Companies] ${API_FAIL_ERROR}${companiesResponse?.status}`
        );
      }

      const companiesData: ApiCompanyData[] = companiesResponse?.data;
      if (companiesData && companiesData.length > 0) {
        setCompanies(companiesData);
      } else {
        const errorMsg = "[Companies] No companies returned.";
        console.log(errorMsg);
      }
    } catch (error) {
      console.log(`[Companies] ${API_FAIL_ERROR}${error}`);
    }
  }, [headersWithAuth]);

  const getSystemsData = useCallback(async () => {
    try {
      const systemsResponse = await axios.post<ApiSystemData[]>(
        SYSTEMS,
        {},
        headersWithAuth
      );
      if (systemsResponse?.status >= 400) {
        console.log(`[Systems] ${API_FAIL_ERROR}${systemsResponse?.status}`);
      }

      const systemsData: ApiSystemData[] = systemsResponse?.data;
      if (systemsData && systemsData.length > 0) {
        setSystems(systemsData);
      } else {
        const errorMsg = "[Systems] No systems returned.";
        console.log(errorMsg);
      }
    } catch (error) {
      console.log(`[Systems] ${API_FAIL_ERROR}${error}`);
    }
  }, [headersWithAuth]);

  const getPreSignedData = useCallback(async () => {
    try {
      const preSignedResponse = await axios.post<PresignedUrlResponse[]>(
        SYSTEM_CERTS,
        {},
        headersWithAuth
      );
      if (preSignedResponse?.status >= 400) {
        console.log(`[PreSigned] ${API_FAIL_ERROR}${preSignedResponse?.status}`);
      }

      const preSignedData: PresignedUrlResponse[] = preSignedResponse?.data;
      if (preSignedData && preSignedData.length > 0) {
        setPreSigned(preSignedData);
      } else {
        const errorMsg = "[PreSigned] No PreSigned returned.";
        console.log(errorMsg);
      }
    } catch (error) {
      console.log(`[PreSigned] ${API_FAIL_ERROR}${error}`);
    }
  }, [headersWithAuth]);


  const getErrorLog = useCallback(async () => {
    try {
      const errorLogsResponse = await axios.get<
        ApiErrorLogDataResponse
      >(ERRORLOGS, headersWithAuth);
      if (errorLogsResponse?.status >= 400) {
        console.log(
          `[Error Log Response] ${API_FAIL_ERROR}${errorLogsResponse?.status}`
        );
      }

      let errorLogsData: ApiErrorLogData[] = errorLogsResponse.data.data;
      if (errorLogsData && errorLogsData.length > 0) {
        console.log("Inside error log data loop")
        setErrorLogsValid(true);
        setErrorLogs(errorLogsData);


      } else {
        const errorMsg = "[Error Log Response] No logs returned.";
        console.log(errorMsg);
      }
    } catch (error) {
      console.log(`[Error Log Response] ${API_FAIL_ERROR}${error}`);
    }
  }, [headersWithAuth]);



  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (usersValid) {
      return;
    }

    setUsersValid(true);
    getUsersData();
  }, [headersWithAuth, isAuthenticated, usersValid, getUsersData]);

  // retrieve the list of companies
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (companiesValid) {
      return;
    }
    setCompaniesValid(true);
    getCompaniesData();
  }, [headersWithAuth, isAuthenticated, companiesValid, getCompaniesData]);

  // retrieve the list of systems
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (systemsValid) {
      return;
    }

    setSystemsValid(true);

    getSystemsData();
    getPreSignedData();
    getErrorLog();
  }, [headersWithAuth, isAuthenticated, systemsValid, getSystemsData, getPreSignedData, getErrorLog]);

  // retrieve the lambda logs
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

  }, [headersWithAuth, isAuthenticated, companiesValid]);

  async function doLogin() {
    navigate("/login");
  }

  async function doLogout() {
    await Auth.signOut();
    resetApp();
    navigate("/login");
  }

  const queryClient = new QueryClient();

  const frontEndContext: FrontEndContextInterface = {
    handleAuthentication,
    headersWithAuth,
    isAuthenticating,
    isAuthenticated,
    doLogin,
    doLogout,
    companiesValid,
    companies,
    usersValid,
    users,
    systemsValid,
    systems,
    preSigned,
    errorLogsValid,
    errorLogs,
    errorLambdaLogs,
    refreshUsers: getUsersData,
    refreshSystems: getSystemsData,
    refreshCompanies: getCompaniesData,
  };

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FrontEndContext.Provider value={frontEndContext}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/user/:userId" element={<User />} />
            <Route path="/company/:companyId" element={<Company />} />
            <Route path="/system/:systemId" element={<System />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/lost" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FrontEndContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
