import React, { SyntheticEvent } from 'react';
import { Snackbar, Alert as MuiAlert, AlertColor } from '@mui/material';

interface AlertProps {
  onClose?: (event: SyntheticEvent<Element, Event>, reason?: string) => void;
  severity: AlertColor;
  sx?: object;

  children?: React.ReactNode;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

interface CustomSnackbarProps {
  open: boolean;
  handleClose: (event: SyntheticEvent<any, Event> | Event, reason?: string) => void;
  message: string;
  severity: AlertColor;
  autoHideDuration?: number;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, handleClose, message, severity, autoHideDuration }) => {
  return (
    <Snackbar 
    data-cy="snackbar-message"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open} 
      autoHideDuration={autoHideDuration || 6000} 
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
      {message}
      {/* <div data-cy="snackbar-message">{message}</div> */}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
