import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import config, { ApiSystemExtraDetails } from "../../config";
import FrontEndContext from "../../context/FrontEndContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const { SYSTEM_EXTRA_DETAILS } = config?.api;

const ErrorMessage = styled("div")({
  color: "red",
});

const DetailItem = styled(Grid)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  padding: theme.spacing(1),
  margin: theme.spacing(1), // Add this line
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DetailLabel = styled(Typography)({
  fontWeight: "bold",
});

const DetailValue = styled(Typography)({
  color: "#333",
});

const SystemDetailsTitle = styled(Typography)({
  marginBottom: "16px",
  textDecoration: "underline",
});

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#FFFAFA",
  color: "black",
  padding: "20px",
  borderRadius: "10px",
});

interface Props {
  systemId: string;
}

const ExpandableRowContent: React.FC<Props> = ({ systemId }) => {
  const [systemDetails, setSystemDetails] =
    useState<ApiSystemExtraDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setHasError] = useState<boolean>(false);
  const { headersWithAuth } = React.useContext(FrontEndContext);

  function formatDateTime(datetime: string): string {
    if (!datetime) return "-";
    const date = new Date(datetime);
    console.log(date);
    return date.toLocaleString();
  }

  useEffect(() => {
    const fetchSystemDetails = async () => {
      try {
        setIsLoading(true);
        setHasError(false);
        const response = await axios.post(
          `${SYSTEM_EXTRA_DETAILS}`,
          { id: systemId },
          headersWithAuth
        );
        if (response.status === 200) {
          setSystemDetails(response.data);
        }
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSystemDetails();
  }, [headersWithAuth, systemId]);

  const renderDetail = (label: string, value: string | number, dataCyAttribute:string) => (
    <DetailItem item xs={6} sm={4} md={2} data-cy={dataCyAttribute}>
      <DetailLabel variant="subtitle2" color="#006400">
        {label}
      </DetailLabel>
      <DetailValue variant="body1" color="#333333">
        {value || "-"}
      </DetailValue>
    </DetailItem>
  );

  const detailsContent = systemDetails ? (
    <Grid container spacing={1}>
    {renderDetail("Node.js:", systemDetails.nodeVersion, 'node')}
    {renderDetail("OS:", systemDetails.osVersion, 'os-version')}
    {renderDetail("Pusher:", systemDetails.pusherVersion, 'pusher')}
    {renderDetail("API:", systemDetails.apiVersion, 'api-version')}
    {renderDetail("Schema:", systemDetails.schemaVersion, 'schema-version')}
    {renderDetail("App:", systemDetails.applicationVersion, 'app-version')}
    {renderDetail("Wheels:", systemDetails.numberOfWheels, 'wheels')}
    {renderDetail(
      "Live Update:",
      formatDateTime(systemDetails.liveViewUpdateTs),
      'live-update'
    )}
    {renderDetail(
      "Live Keep Alive:",
      formatDateTime(systemDetails.liveViewKeepAliveTs),
      'live-keep-alive'
    )}
    {renderDetail(
      "Active Planting Date:",
      formatDateTime(systemDetails.activePlantingUpdateTimestamp),
      'active-planting-date'
    )}
    </Grid>
  ) : (
    <ErrorMessage>Error: Unable to fetch system details.</ErrorMessage>
  );

  return (
    
    <Container data-cy={`expandable-row-${systemId}`}>
      <SystemDetailsTitle color="#000080" variant="h6">
        System Details
      </SystemDetailsTitle>
      {isLoading ? <div>Loading...</div> : detailsContent}
    </Container>
  );
};

export default ExpandableRowContent;
