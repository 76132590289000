import React from "react";
import Button from "react-bootstrap/Button";
import "./index.css";
import { BsArrowRepeat } from "react-icons/bs";

interface CustomButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  variant?: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  className?: string;
  icon?: React.ElementType;
  iconColor?: string;
  text?: string;
  [x: string]: any;
}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { isLoading, disabled, variant, type, icon, iconColor, text, ...rest } =
    props;

  const renderIcon = () => {
    if (icon) {
      return React.createElement(icon, {
        className: text ? "mr-2" : "",
        style: { color: iconColor, border: "none" },
      });
    }
    return null;
  };

  const renderText = () => {
    if (text) {
      return <span>{text}</span>;
    }
    return null;
  };

  const renderLoading = () => {
    if (isLoading) {
      // Replace with your loading icon
      return (
        <span>
          {isLoading && <BsArrowRepeat className="spinning" />}
          {icon && React.createElement(icon, { className: "mr-2" })}{" "}
        </span>
      );
    }
    return null;
  };

  return (
    <Button
      variant={variant}
      type={type}
      disabled={disabled || isLoading}
      className={`${icon && text ? "has-icon-and-text" : ""} ${
        disabled ? "custom-btn" : ""
      }`}
      {...rest}
    >
      {renderLoading()}
      {renderIcon()}
      {renderText()}
    </Button>
  );
};

export default CustomButton;
