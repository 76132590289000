import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import CompaniesList from "../../components/CompaniesList";
import UsersList from "../../components/UsersList";
import SystemsList from "../../components/SystemsList";
import ErrorLogsList from "../../components/ErrorLogs";
import OperationHistoryList from "../../components/OperationsHistory";
const HEADER_NAME = "";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isAuthenticated,
    isAuthenticating,
    companiesValid,
    companies,
    usersValid,
    users,
    systemsValid,
    systems,
    errorLogsValid,
    errorLogs,
  } = React.useContext(FrontEndContext);

  const [tabIndex, setTabIndex] = useState(
    () => Number(localStorage.getItem("lastTabIndex")) || 0
  );

  const handleTabChange = (_event: any, newTabIndex: number) => {
    localStorage.setItem("lastTabIndex", String(newTabIndex));
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }

    if (location.state?.fromEditPage) {
      setTabIndex(Number(localStorage.getItem("lastTabIndex")) || 0);
    }
  }, [isAuthenticated, isAuthenticating, navigate, location.state]);

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <Box className="home-container py-3">
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} className="px-4">
            <Tab data-cy="company-tab" label={<Typography variant="h5">Company</Typography>} />
            <Tab data-cy="user-tab" label={<Typography variant="h5">User</Typography>} />
            <Tab data-cy="system-tab" label={<Typography variant="h5">System</Typography>} />
            <Tab data-cy="errorLog-tab" label={<Typography variant="h5">Error Log</Typography>} />
            <Tab data-cy="operationHistory-tab"
              label={<Typography variant="h5">Operation History</Typography>}
            />
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {tabIndex === 0 && (
            <Box className="companies-container py-3">
              <CompaniesList
                companiesValid={companiesValid}
                companies={companies}
              />
            </Box>
          )}
          {tabIndex === 1 && (
            <Box className="users-container py-3">
              <UsersList usersValid={usersValid} users={users} />
            </Box>
          )}
          {tabIndex === 2 && (
            <Box className="systems-container py-3">
              <SystemsList systemsValid={systemsValid} systems={systems} />
            </Box>
          )}
          {tabIndex === 3 && (
            <Box className="errorlogs-container py-3">
              <ErrorLogsList
                errorLogsValid={errorLogsValid}
                errorLogsList={errorLogs}
              />
            </Box>
          )}

          {tabIndex === 4 && (
            <OperationHistoryList />
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default Home;
