const SERVER_SCHEME = "https";
const SERVER_HOST = process.env.REACT_APP_API;
const SERVER_URL = `${SERVER_SCHEME}://${SERVER_HOST}`;
const USER_ROOT = `${SERVER_URL}/web_user`;
const USERS = `${USER_ROOT}/web_users`;
const CREATE_USERS = `${USER_ROOT}/create`;
const DELETE_USER = `${USER_ROOT}/delete`;
const UPDATE_USER = `${USER_ROOT}/update`;
const UPDATE_USER_PASSWORD = `${USER_ROOT}/update_password`;
const SYSTEM_ROOT = `${SERVER_URL}/system`;
const SYSTEMS = `${SYSTEM_ROOT}/systems`;
const SYSTEM_EXTRA_DETAILS = `${SYSTEM_ROOT}/extra_details`;
const SYSTEM_CERTS = `${SYSTEM_ROOT}/certs`;
const CREATE_SYSTEMS = `${SYSTEM_ROOT}/create`;
const UPDATE_SYSTEMS = `${SYSTEM_ROOT}/update`;
const COMPANY_ROOT = `${SERVER_URL}/company`;
const COMPANIES = `${COMPANY_ROOT}/companies`;
const COMPANY = `${COMPANY_ROOT}/company`;
const UPDATE_COMPANY = `${COMPANY_ROOT}/update`;
const DELETE_SYSTEMS = `${SYSTEM_ROOT}/delete`;
const CREATE_COMPANY = `${COMPANY_ROOT}/create`;
const DELETE_COMPANY = `${COMPANY_ROOT}/delete`;
const OPERATIONHISTORY_ROOT = `${SERVER_URL}/logs`;
const OPERATIONHISTORIES = `${OPERATIONHISTORY_ROOT}/admin_logs`;
const ERRORLOGS = `${SERVER_URL}/logs/lambda/status`;
const ERRORLOGS_LAMBDA = `${SERVER_URL}/logs/lambda`;

const API_FAIL_ERROR = "Could not fetch data from server";

const config = {
  s3: {
    REGION: "ca-central-1",
    BUCKET: "config-bucket-325591102945-ca-central-1",
  },
  apiGateway: {
    REGION: "ca-central-1",
    URL: process.env.REACT_APP_API,
  },
  cognito: {
    REGION: "ca-central-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: "None",
  },
  api: {
    API_FAIL_ERROR,
    USERS,
    CREATE_USERS,
    DELETE_USER,
    UPDATE_USER,
    UPDATE_USER_PASSWORD,
    UPDATE_COMPANY,
    SYSTEMS,
    CREATE_SYSTEMS,
    UPDATE_SYSTEMS,
    DELETE_SYSTEMS,
    SYSTEM_EXTRA_DETAILS,
    OPERATIONHISTORIES,
    COMPANIES,
    COMPANY,
    CREATE_COMPANY,
    DELETE_COMPANY,
    SYSTEM_CERTS,
    ERRORLOGS,
    ERRORLOGS_LAMBDA,
  },
  app: {
    ID_CREATE_NEW: "create",
  },
};

export interface ApiCompanyData {
  id: number;
  name: string;
  address1?: string;
  address2?: string;
  city: string;
}

export interface ApiOperationHistory {
  userName: string;
  entityType: string;
  operation: string;
  operationValues: string;
  timestamp: string;
}

export interface ApiUserData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  companyName: string;
  lastLoginTs: string;
}

export interface ApiSystemData {
  id: string;
  name: string;
  companyId: number;
  companyName: string;
  status: string;
  updateTs: string;
}

export interface ApiCompanyDataExt extends ApiCompanyData {
  webUsers: ApiUserData[];
  systems: ApiSystemData[];
}

export interface PresignedUrlResponse {
  success: boolean;
  error: string | null;
  url: string;
}

// export interface ApiErrorLogData {
//   id: string;
//   description: string;
// }

export interface LogEntry {
  eventId: string;
  ingestionTime: number;
  logStreamName: string;
  message: string;
  timestamp: number;
}

export interface ApiErrorLogData {
  lambdaName: string;
  logs: LogEntry[];
}

export interface ApiSystemExtraDetails {
  id: string;
  nodeVersion: string;
  osVersion: string;
  pusherVersion: string;
  apiVersion: string;
  schemaVersion: string;
  applicationVersion: string;
  numberOfWheels: number;
  liveViewUpdateTs: string;
  liveViewKeepAliveTs: string;
  activePlantingUpdateTimestamp: string;
}

export interface ApiErrorLogData {
  alarmName: string
  functionName: string;
  state: string;
}

export interface ApiErrorLogDataResponse {
  status: string;
  data: ApiErrorLogData[];
  nextToken: string;
}


export interface ApiLambdaErrorLog {
  timestamp: string;
  message: string;
  logStreamName: string;
}
export interface ApiLambdaErrorLogResponse {
  status: string;
  data: ApiLambdaErrorLog[];
  nextToken: string;
}

export default config;
